<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            clearable
            placeholder="请输入手机号"
            class="search-input ml-10"
            v-model="searchObj.phone"
          ></el-input>
          <el-select placeholder="选择核销来源" class="search-input ml-10" v-model="searchObj.orderType">
            <el-option
              v-for="(item, index) in order_type"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-date-picker
            class="ml-10"
            v-model="searchObj.date"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button type="primary" icon="el-icon-search" class="ml-10" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="自提码" prop="qrCode"></el-table-column>
            <!-- 商户名称暂时隐藏 -->
            <el-table-column label="用户" prop="nickname"></el-table-column>
            <el-table-column label="联系方式" prop="phone"></el-table-column>
            <el-table-column label="订单商品" prop="goodsName"></el-table-column>
            <el-table-column label="商品来源">
              <template slot-scope="scope">
                <span>{{getString(1, scope.row.orderType)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="核销人员" prop="name"></el-table-column>
            <el-table-column label="核销时间" prop="photoTime">
              <template slot-scope="scope">{{scope.row.photoTime?scope.row.photoTime:'--'}}</template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import request from "@/utils/request";
import Pagination from "@/components/Pagination";
export default {
  name: "ripoff_list",
  components: { Pagination },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        phone: "",
        // storeId: null,
        orderType: null,
        date: [],
        // name: ""
      },
      store_list: [],
      order_type: [
        {
          id: 1,
          title: "正常交易"
        },
        {
          id: 2,
          title: "积分活动"
        },
        {
          id: 3,
          title: "砍价活动"
        },
        {
          id: 5,
          title: "商城"
        },
        {
          id: 6,
          title: "多人拼团"
        },
        {
          id: 7,
          title: "限时抢购"
        }
      ],
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      order_status: [
        {
          id: 0,
          title: "未支付"
        },
        {
          id: 1,
          title: "已支付"
        },
        {
          id: 2,
          title: "已发货"
        },
        {
          id: 3,
          title: "已完成"
        },
        {
          id: 4,
          title: "待退款"
        },
        {
          id: 5,
          title: "已退款"
        }
      ]
    };
  },
  mounted() {
    this.getStores();
    this.initData();
  },
  methods: {
    // 初始化数据
    initData(searchData) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      data = Object.assign(data, searchData);
      request({
        url: "/order-service/order/query/photo",
        method: "post",
        data
      }).then(res => {
        this.tableData = res.data.body.list;
        this.$set(this, "tableData", res.data.body.list);
        this.total = res.data.body.total;
      });
    },
    // 搜索事件
    search() {
      let data = {
        // storeId: this.searchObj.storeId,
        phone: this.searchObj.phone,
        orderType: this.searchObj.orderType,
        // name: this.searchObj.name,
        photoTime1: this.searchObj.date && this.searchObj.date[0],
        photoTime2: this.searchObj.date && this.searchObj.date[1]
      };
      // this.$message("点击查询");
      this.initData(data);
    },

    // 重置搜索关键词
    reset() {
      (this.searchObj = {
        phone: "",
        // storeId: null,
        orderType: null,
        name:'',
        date: []
      }),
        this.initData();
    },
    // 获取门店列表
    getStores() {
      request({
        url: "/goods-service/store/listOfPage",
        method: "get"
      }).then(res => {
        // // console.log("门店列表", res);
        this.store_list = res.data.body.list;
        // this.tableData = res.data.body;
      });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 0) {
        this.order_status.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach(e => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
